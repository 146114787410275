<template>
  <v-slide-x-reverse-transition>
    <v-snackbar v-model="active" top right multi-line v-bind="snackbar.props">
      {{ snackbar.message }}
      <v-btn
        color="white"
        text
        @click="active = false"
      >
        Sluiten
      </v-btn>
    </v-snackbar>
  </v-slide-x-reverse-transition>
</template>

<script>
import {mapState} from 'vuex';

export default {
  computed: {
    ...mapState({
      snackbar: state => state.notify.snackbar,
    }),
    active: {
      set(isActive) {
        this.$store.commit('notify/show', isActive);
      },
      get() {
        return this.snackbar.active;
      },
    },
  },
};
</script>
