<template>
  <section class="drawer-row d-flex align-center justify-space-between">
    <div>
      <v-btn
        class="primary white--text btn-medium display-1"
        icon
        nuxt
        @click="goTo"
      >
        <font-awesome-icon :icon="['fas', 'chevron-left']"/>
      </v-btn>
      <span class="primary--text caption pl-1">Terug</span>
    </div>
    <v-btn v-if="$route.name === 'index-id'" icon dark class="btn-medium display-1 primary darken-1" @click="editItem">
      <font-awesome-icon :icon="['fas', 'pen']"/>
    </v-btn>
  </section>
</template>

<script>
export default {
  methods: {
    editItem() {
      this.$router.push({name: 'index-id-edit'});
    },
    goTo() {
      if (this.$route.name === 'index-id') {
        this.$router.push({name: 'index'});
      } else if (this.$route.name === 'index-id-edit') {
        this.$router.push({name: 'index-id'});
      } else if (this.$route.name === 'index-create') {
        this.$router.push({name: 'index'});
      } else if (this.$route.name === 'gebruikers-index-id') {
        this.$router.push({name: 'gebruikers-index'});
      } else if (this.$route.name === 'onderwijsinstellingen-index-id') {
        this.$router.push({name: 'onderwijsinstellingen-index'});
      } else if (this.$route.name === 'organisaties-index-id') {
        this.$router.push({name: 'organisaties-index'});
      } else if (this.$route.name === 'organisaties-id-bewerken') {
        this.$router.push({name: 'organisaties-index-id'});
      } else {
        this.$router.push({name: 'nieuws'});
      }
    },
  },
};
</script>
