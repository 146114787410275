export const state = () => ({
  items: [],
  loading: false,
  scrollActive: false,
  filtersDrawer: true,
});

export const mutations = {
  SET_ALL(state, payload) {
    state.items = payload;
  },
  UPDATE_ITEM(state, payload) {
    state.items = state.items.map((item) => {
      if (item.id === payload.id) {
        return Object.assign({}, item, payload);
      }
      return item;
    });
  },
  ADD_ITEM(state, payload) {
    state.items.push(payload);
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_COMMENT_SCROLL(state, payload) {
    state.scrollActive = payload;
  },
};

export const actions = {
  async getMultiple({commit}, payload) {
    try {
      const {data} = await this.$axios.get('/projects', {params: payload});
      commit('SET_ALL', data.data);
    } catch (e) {
      console.error(e);
    }
  },
  async getAll({commit}) {
    try {
      const {data} = await this.$axios.get('/projects');
      commit('SET_ALL', data.data);
    } catch (e) {
      console.error(e);
    }
  },
  setScroll({commit}, payload) {
    commit('SET_COMMENT_SCROLL', payload);
  },
};
