<template>
  <v-slide-x-transition>
    <v-row v-show="notificationsDrawer"
           class="notifications background lighten-5 shadow-overlay flex-column fill-height" no-gutters>
      <v-col class="px-8 pb-8">
        <v-row align="center">
          <v-col tag="h1" cols="10" class="subtitle-1 text-uppercase primary--text">
            Notificaties
          </v-col>
          <v-col cols="2" class="text-right pr-0">
            <v-btn icon color="primary darken-1" @click="closeNotifications">
              <font-awesome-icon :icon="['far', 'times']" size="lg"/>
            </v-btn>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col class="pr-5 shrink">
            <span class="body-1">Sorteer: </span>
          </v-col>
          <v-col class="px-0" cols="4">
            <v-select
              v-model="sort"
              filled
              dense
              flat
              solo
              hide-details
              :items="sortOptions"
              menu-props="offsetY"
              class="caption"
            />
          </v-col>
        </v-row>

        <div v-if="user.notifications && user.notifications.length < 1">
          <v-container class="notifications-list border-chat py-0 background">
            <v-row justify="space-between" align="center">
              <v-col cols="11">
                <div class="title pb-1 secondary--text">Er zijn geen notificaties om weer te geven.</div>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <v-fade-transition
          v-else
          group
        >
          <div v-for="notification in orderedItems(notifications)" :key="notification.id">
            <v-hover
              v-slot:default="{ hover }">
              <v-container v-if="$moment(notification.created_at).diff($moment(currentDate), 'days') >= -14 "
                           @click="readNotification(notification)" class="notifications-list border-chat py-0 mb-2"
                           :style="{cursor: hover ? 'pointer' : 'default'}"
                           :class="[{'background darken-1' : !notification.read_at && !hover}, {'background' : notification.read_at && !hover}, {'primary' : hover}]">
                <v-row>
                  <v-col>
                    <div class="title pb-1"
                         :class="[{'text--lighten-1 font-weight-regular' : notification.read_at}, hover ? 'white--text' : 'secondary--text']">
                      {{ notification.data.user }} {{ notification.data.message }} {{ notification.data.new_state || '' }}
                    </div>
                    <div class="text-truncate" :class="!notification.read_at ? 'font-weight-bold' : 'font-weight-normal'">{{ notification.data.comment }}</div>
                  </v-col>
                  <v-col class="shrink">
                    <div class="caption" :class="hover ? 'white--text' : 'primary--text'">#{{
                      notification.data.activity_id }}
                    </div>
                    <time :datetime="notification.created_at" class="title font-weight-light text-no-wrap"
                          :class="hover ? 'white--text' : 'secondary--text'" style="min-width: 3.5em">{{
                      $moment(notification.created_at).fromNow(true) }}
                    </time>
                  </v-col>
                </v-row>
              </v-container>
            </v-hover>
          </div>

        </v-fade-transition>
      </v-col>
    </v-row>
  </v-slide-x-transition>
</template>

<script>
import {mapState} from 'vuex';

export default {
  data() {
    return {
      sort: 'Nieuwste',
      sortOptions: ['Nieuwste', 'Oudste'],
      currentDate: new Date(),
      order: 'created_at',
      orderDirection: 'asc',
    };
  },
  computed: {
    ...mapState({
      notificationsState: state => state.notificationsDrawer,
      notifications: state => state.notifications.notifications,
    }),
    notificationsDrawer: ({
      set(state) {
        this.$store.commit('SET_NOTIFICATIONS', state);
      },
      get() {
        return this.notificationsState;
      },
    }),
  },
  watch: {
    $route() {
      this.closeNotifications();
    },
    sort() {
      this.setOrder(this.sort);
    },
  },
  created() {
    this.getNotifications();
  },
  methods: {
    closeNotifications() {
      this.$store.commit('SET_NOTIFICATIONS', false);
    },
    async readNotification(event) {
      if (event.read_at === null) {
        try {
          await this.$axios.post('notifications', {
            notification_id: event.id,
          });
        } catch (e) {
          console.log(e);
        }
      }
      this.$store.commit('SET_NOTIFICATIONS', false);
      this.$store.commit('notifications/READ_NOTIFICATION', event);
      this.$router.push(`/${event.data.activity_id}`);
    },
    async getNotifications() {
      await this.$store.dispatch('notifications/getNotifications');
    },
    orderedItems(items) {
      this.$nextTick();
      if (this.orderDirection !== 'desc') {
        return items.filter((item) => {
          if (item[this.order]) return item;
        });
      } else {
        return items.filter((item) => {
          if (item[this.order]) return item;
        }).reverse();
      }
    },
    setOrder(sort) {
      switch (sort) {
        case 'Nieuwste':
          this.order = 'created_at';
          this.orderDirection = 'asc';
          break;
        case 'Oudste':
          this.order = 'created_at';
          this.orderDirection = 'desc';
          break;
      }
    },
  },
};
</script>

<style scoped lang="sass">
.notifications
  position: fixed
  width: 100vw
  z-index: 8
  top: 0
  height: 100vh
  max-height: 100vh
  overflow: scroll

::v-deep p
  margin-bottom: 0 !important

@media screen and (min-width: map-get($grid-breakpoints, 'sm'))
  .notifications
    width: 55vw

@media screen and (min-width: map-get($grid-breakpoints, 'lg'))
  .notifications
    width: 40vw

</style>
