<template>
  <v-app class="background lighten-5">
    <v-img :src="require('~/assets/img/background-images.svg')" max-height="100vh">
      <v-container>
        <v-row justify="center">
          <v-col cols="5">
            <img :src="require('~/assets/img/logo.svg')" class="py-24">
          </v-col>
        </v-row>
        <v-row class="text-center">
          <v-col>
            <template v-if="error.statusCode === 410 || error.statusCode === 404">
              <h1 class="body-2 primary--text text-uppercase pb-8">{{ page.title }}</h1>
              <!--eslint-disable-next-line-->
              <div v-html="page.tekst" class="body-2 primary--text pb-22"/>
              <v-btn depressed color="primary darken-1" to="/" nuxt>
                Terug naar dashboard
              </v-btn>
            </template>
            <h1 v-else>
              Er is een fout opgetreden
            </h1>
          </v-col>
        </v-row>
      </v-container>
    </v-img>
  </v-app>
</template>

<script>
import {mapState} from 'vuex';

export default {
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  computed: mapState(['page']),
  async created() {
    await this.$store.dispatch('getPage', '404');
  },
  head() {
    return {
      title: this.page.meta ? this.page.meta.title : this.page.title,
      meta: [
        this.page.meta && this.page.meta.description
          ? {
            hid: 'description',
            name: 'description',
            content: this.page.meta.description,
          }
          : {},
      ],
    };
  },
};
</script>
