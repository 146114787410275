<template>
  <v-container class="pa-0 drawer">
    <v-navigation-drawer
      v-model="drawerState"
      height="100%"
      :permanent="this.$breakpoint.is.smAndUp"
      app
      color="primary"
      class="shadow"
      dark
      :mini-variant="mini"
    >
      <v-card flat tile :height="!mini ? '210' : '190'" color="primary lighten-2" :class="{'card': !mini}">
        <v-card-title class="justify-center flex-column" :class="!mini ? 'pt-10' : 'pt-6'">
          <v-img
            :class="!mini ? 'border-radius-15' : 'border-radius-4 shadow-img'"
            aspect-ratio="1"
            :width="!mini ? '50%' : '100%'"
            :style="!mini ? 'max-height: 112px;' : 'max-height: 48px;'"
            :src="user.image"
            :max-width="112"
          >
            <template v-slot:placeholder>
              <v-skeleton-loader light type="image"/>
            </template>
          </v-img>
          <div v-show="!mini" class="subtitle-2 pt-2">
            {{ user.name }}
          </div>
        </v-card-title>

        <v-card-actions :class="!mini ? 'justify-center' : 'flex-column justify-center'">
          <v-btn
            center
            icon
            class="primary darken-1"
            :class="!mini ? 'mr-2 btn-large' : 'btn-medium'"
            @click="toggleUserEdit"
          >
            <font-awesome-icon :icon="['fas', 'user-edit']" :size="!mini ? 'sm' : 'xs'"/>
          </v-btn>

          <v-btn
            center
            icon
            nuxt
            class="primary darken-1"
            :class="!mini ? 'mr-2 btn-large' : 'ml-0 mt-2 btn-medium'"
            @click="toggleNotifications"
          >
            <v-badge right overlay color="red">
              <template v-if="unread > 0" v-slot:badge>{{ unread }}</template>
              <font-awesome-icon :icon="['fas', 'bell']" :size="!mini ? 'sm' : 'xs'"/>
            </v-badge>
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-list :class="!mini ? 'pt-12' : 'pt-2'" nav>
        <v-hover v-for="item in itemsGebruiker" :key="item.title" v-slot:default="{ hover }">
          <v-list-item
            v-bind="item.route"
            class="my-5"
            active-class="primary lighten-2"
            :class="{'primary lighten-2' : hover}"
          >
            <v-list-item-title class="caption" :class="!mini ? 'pl-12' : 'text-center'">
              <font-awesome-icon :icon="item.icon" class="pr-0 pr-lg-2" size="lg" fixed-width/>
              <span v-show="!mini">{{ item.title }}</span>
            </v-list-item-title>
          </v-list-item>
        </v-hover>
      </v-list>
      <template v-slot:append>
        <img
          v-show="!mini"
          src="~/assets/img/logo-white.svg"
          alt="Vervoerregio Amsterdam"
          class="px-8 pb-3"
        >
        <v-btn block tile color="primary darken-1" height="62px" @click="logOut">
          <span>
            <font-awesome-icon :icon="['fas', 'sign-out']" class="subtitle-2" :class="{'mr-3' : !mini}"/>
          </span>
          <span v-show="!mini" class="caption font-weight-medium text-capitalize">
            Uitloggen
          </span>
        </v-btn>
      </template>
      <v-btn v-show="$breakpoint.is.mdOnly" icon x-small class="show--menu primary lighten-2" @click="mini = !mini">
        <font-awesome-icon v-if="mini" :icon="['fas', 'chevron-right']" size="2x"/>
        <font-awesome-icon v-else :icon="['fas', 'chevron-left']" size="2x"/>
      </v-btn>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import {mapState, mapGetters} from 'vuex';

export default {
  data() {
    return {
      mini: this.$breakpoint.is.smOnly,
      itemsGebruiker: [
        {
          title: 'Dashboard',
          route: {to: '/', nuxt: true},
          icon: ['fas', 'home'],
        },
        {
          title: 'Onderwijsinstellingen',
          route: {to: '/onderwijsinstellingen', nuxt: true},
          icon: ['fas', 'hands-helping'],
        },
        {
          title: 'Organisaties',
          route: {to: '/organisaties', nuxt: true},
          icon: ['fas', 'building'],
        },
        // VER-3/VER-5
        {
          title: 'Projecten',
          route: {to: '/projecten', nuxt: true},
          icon: ['fas', 'tasks'],
        },
        {
          title: 'Gebruikers',
          route: {to: '/gebruikers', nuxt: true},
          icon: ['fas', 'users'],
        },
        {
          title: 'Nieuws',
          route: {to: '/nieuws', nuxt: true},
          icon: ['fas', 'newspaper'],
        },
        {
          title: 'FAQ',
          route: {to: '/faq', nuxt: true},
          icon: ['fas', 'question-circle'],
        },
        {
          title: 'Contact',
          route: {to: '/contact', nuxt: true},
          icon: ['fas', 'phone-volume'],
        },
      ],
    };
  },

  computed: {
    ...mapState({
      editUserState: state => state.editUser,
      notificationsState: state => state.notificationsDrawer,
      drawerState: state => state.drawer,
    }),
    ...mapGetters({
      unread: 'notifications/unread',
    }),
    drawerState: {
      get() {
        return this.$store.state.drawer;
      },
      set(state) {
        if (state !== this.$store.state.drawer) {
          this.$store.commit('SET_DRAWER', !this.drawerState);
        }
      },
    },
  },
  methods: {
    async logOut() {
      try {
        await this.$auth.logout();
        this.$router.push('/inloggen');
      } catch (e) {
        console.log(e);
      }
    },
    toggleUserEdit() {
      this.$store.commit('SET_EDIT_USER', !this.editUserState);
    },
    toggleNotifications() {
      this.$store.commit('SET_NOTIFICATIONS', !this.notificationsState);
    },
  },
};
</script>

<style lang="sass" scoped>
.drawer
  z-index: 9

.card
  position: relative

  &::after
    position: absolute
    content: ''
    height: 850px
    width: 950px
    border-radius: 50%
    background-color: var(--v-primary-lighten2)
    transform: translate(-36.5%, -102%)
    z-index: -1

  > .v-card__actions
    transform: translateY(20%)

.show--menu
  position: fixed
  top: 90%
  right: 0
  transform: translateX(50%)
  z-index: 20

::v-deep .v-list--nav

  .v-list-item,
  .v-list-item:before
    border-radius: 0

// Fix for Edge
::v-deep .v-navigation-drawer__content
  -ms-overflow-style: none
</style>
