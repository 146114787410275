import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _344d36f3 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _44c2ef2e = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _773e89dd = () => interopDefault(import('../pages/gebruikers/index.vue' /* webpackChunkName: "pages/gebruikers/index" */))
const _f5fd12c0 = () => interopDefault(import('../pages/gebruikers/index/index.vue' /* webpackChunkName: "pages/gebruikers/index/index" */))
const _5c86bdf0 = () => interopDefault(import('../pages/gebruikers/index/_id.vue' /* webpackChunkName: "pages/gebruikers/index/_id" */))
const _4d623c2e = () => interopDefault(import('../pages/inloggen.vue' /* webpackChunkName: "pages/inloggen" */))
const _5becfec7 = () => interopDefault(import('../pages/nieuws/index.vue' /* webpackChunkName: "pages/nieuws/index" */))
const _adb95762 = () => interopDefault(import('../pages/onderwijsinstellingen/index.vue' /* webpackChunkName: "pages/onderwijsinstellingen/index" */))
const _15a5b8dc = () => interopDefault(import('../pages/onderwijsinstellingen/index/index.vue' /* webpackChunkName: "pages/onderwijsinstellingen/index/index" */))
const _d31a3b0c = () => interopDefault(import('../pages/onderwijsinstellingen/index/_id.vue' /* webpackChunkName: "pages/onderwijsinstellingen/index/_id" */))
const _29bfd469 = () => interopDefault(import('../pages/organisaties/index.vue' /* webpackChunkName: "pages/organisaties/index" */))
const _0dcba82c = () => interopDefault(import('../pages/organisaties/index/index.vue' /* webpackChunkName: "pages/organisaties/index/index" */))
const _2e0665d2 = () => interopDefault(import('../pages/organisaties/index/_id/index.vue' /* webpackChunkName: "pages/organisaties/index/_id/index" */))
const _1fa93150 = () => interopDefault(import('../pages/projecten/index.vue' /* webpackChunkName: "pages/projecten/index" */))
const _6e99e84a = () => interopDefault(import('../pages/projecten/index/index.vue' /* webpackChunkName: "pages/projecten/index/index" */))
const _7562ba43 = () => interopDefault(import('../pages/projecten/index/create.vue' /* webpackChunkName: "pages/projecten/index/create" */))
const _551cc146 = () => interopDefault(import('../pages/projecten/index/_id/index.vue' /* webpackChunkName: "pages/projecten/index/_id/index" */))
const _7c7f2146 = () => interopDefault(import('../pages/projecten/index/_id/edit.vue' /* webpackChunkName: "pages/projecten/index/_id/edit" */))
const _6254138a = () => interopDefault(import('../pages/wachtwoord-vergeten/index.vue' /* webpackChunkName: "pages/wachtwoord-vergeten/index" */))
const _3086633e = () => interopDefault(import('../pages/wachtwoord-vergeten/reset/_token.vue' /* webpackChunkName: "pages/wachtwoord-vergeten/reset/_token" */))
const _5a365d7f = () => interopDefault(import('../pages/nieuws/_slug.vue' /* webpackChunkName: "pages/nieuws/_slug" */))
const _c9efd446 = () => interopDefault(import('../pages/wachtwoord-instellen/_token.vue' /* webpackChunkName: "pages/wachtwoord-instellen/_token" */))
const _7e4ef6e1 = () => interopDefault(import('../pages/organisaties/_id/bewerken.vue' /* webpackChunkName: "pages/organisaties/_id/bewerken" */))
const _1830126a = () => interopDefault(import('../pages/organisaties/_id/create.vue' /* webpackChunkName: "pages/organisaties/_id/create" */))
const _bcff3536 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _49828c28 = () => interopDefault(import('../pages/index/index.vue' /* webpackChunkName: "pages/index/index" */))
const _0e834396 = () => interopDefault(import('../pages/index/create.vue' /* webpackChunkName: "pages/index/create" */))
const _332d59da = () => interopDefault(import('../pages/index/_id/index.vue' /* webpackChunkName: "pages/index/_id/index" */))
const _4fa2a1d9 = () => interopDefault(import('../pages/index/_id/edit.vue' /* webpackChunkName: "pages/index/_id/edit" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/contact",
    component: _344d36f3,
    name: "contact"
  }, {
    path: "/faq",
    component: _44c2ef2e,
    name: "faq"
  }, {
    path: "/gebruikers",
    component: _773e89dd,
    children: [{
      path: "",
      component: _f5fd12c0,
      name: "gebruikers-index"
    }, {
      path: ":id",
      component: _5c86bdf0,
      name: "gebruikers-index-id"
    }]
  }, {
    path: "/inloggen",
    component: _4d623c2e,
    name: "inloggen"
  }, {
    path: "/nieuws",
    component: _5becfec7,
    name: "nieuws"
  }, {
    path: "/onderwijsinstellingen",
    component: _adb95762,
    children: [{
      path: "",
      component: _15a5b8dc,
      name: "onderwijsinstellingen-index"
    }, {
      path: ":id",
      component: _d31a3b0c,
      name: "onderwijsinstellingen-index-id"
    }]
  }, {
    path: "/organisaties",
    component: _29bfd469,
    children: [{
      path: "",
      component: _0dcba82c,
      name: "organisaties-index"
    }, {
      path: ":id",
      component: _2e0665d2,
      name: "organisaties-index-id"
    }]
  }, {
    path: "/projecten",
    component: _1fa93150,
    children: [{
      path: "",
      component: _6e99e84a,
      name: "projecten-index"
    }, {
      path: "create",
      component: _7562ba43,
      name: "projecten-index-create"
    }, {
      path: ":id",
      component: _551cc146,
      name: "projecten-index-id"
    }, {
      path: ":id/edit",
      component: _7c7f2146,
      name: "projecten-index-id-edit"
    }]
  }, {
    path: "/wachtwoord-vergeten",
    component: _6254138a,
    name: "wachtwoord-vergeten"
  }, {
    path: "/wachtwoord-vergeten/reset/:token",
    component: _3086633e,
    name: "wachtwoord-vergeten-reset-token"
  }, {
    path: "/nieuws/:slug",
    component: _5a365d7f,
    name: "nieuws-slug"
  }, {
    path: "/wachtwoord-instellen/:token?",
    component: _c9efd446,
    name: "wachtwoord-instellen-token"
  }, {
    path: "/organisaties/:id/bewerken",
    component: _7e4ef6e1,
    name: "organisaties-id-bewerken"
  }, {
    path: "/organisaties/:id/create",
    component: _1830126a,
    name: "organisaties-id-create"
  }, {
    path: "/",
    component: _bcff3536,
    children: [{
      path: "",
      component: _49828c28,
      name: "index"
    }, {
      path: "create",
      component: _0e834396,
      name: "index-create"
    }, {
      path: ":id",
      component: _332d59da,
      name: "index-id"
    }, {
      path: ":id/edit",
      component: _4fa2a1d9,
      name: "index-id-edit"
    }]
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
