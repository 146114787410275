<template>
  <v-footer v-if="$breakpoint.is.xsOnly" :min-height="50" app fixed class="pa-0">
    <mobile-menu/>
  </v-footer>
</template>

<script>
import MobileMenu from '~/layouts/partials/MobileMenu';

export default {
  components: {
    MobileMenu,
  },
};
</script>
