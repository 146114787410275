import Vue from 'vue';
import {library, config} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

import {
  faChevronDown as fasFaChevronDown,
  faInfoCircle,
  faCaretDown,
} from '@fortawesome/free-solid-svg-icons';

import {
  faArrowLeft,
  faArrowRight,
  faLineColumns,
  faBell,
  faBuilding,
  faCheckSquare,
  faChevronRight as fasFaChevronRight,
  faChevronLeft as fasFaChevronLeft,
  faChevronDoubleDown,
  faChild,
  faEdit,
  faEllipsisV,
  faEnvelope,
  faFax,
  faFileDownload,
  faGripVertical,
  faHandsHelping,
  faHome,
  faList,
  faMapMarkerAlt,
  faNewspaper,
  faPen,
  faPhone,
  faPhoneVolume,
  faRepeat,
  faSearch,
  faSignOut,
  faSlidersV,
  faSort,
  faTasks,
  faTimesCircle,
  faUsers,
  faUserEdit,
  faQuestionCircle,
  faCheck,
  faExclamationTriangle,
  faCloudDownloadAlt,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faInstagram,
  faLinkedinIn,
  faTelegramPlane,
  faTwitter,
  faFacebookF,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

import {
  faCalendarAlt,
  faEye,
  faGlobeEurope,
  faPlus,
  faTimes,
  faEyeSlash,
  faUserTie,
  faBars,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faPlusCircle,
  faDownload,
  faClone,
  faTrashAlt,
  faChevronDown as falFaChevronDown,
  faChevronRight,
  faChevronLeft,
  faLongArrowLeft,
  faLongArrowRight,
  faMinusSquare,
  faSquare,
  faChevronUp,
  faEllipsisH,
  faUser,
  faFile,
} from '@fortawesome/pro-light-svg-icons';

config.autoAddCss = false;

library.add(
  faArrowLeft,
  faArrowRight,
  faPlusCircle,
  faTrashAlt,
  faDownload,
  faChild,
  faClone,
  faFax,
  faFileDownload,
  faGlobeEurope,
  faTrashAlt,
  falFaChevronDown,
  fasFaChevronDown,
  faChevronDoubleDown,
  faChevronRight,
  faChevronLeft,
  faHandsHelping,
  faNewspaper,
  faPhone,
  faPhoneVolume,
  faPen,
  faSquare,
  faCheck,
  faLongArrowLeft,
  faLongArrowRight,
  faMinusSquare,
  faChevronUp,
  faHome,
  faEdit,
  faSearch,
  faPlus,
  faInfoCircle,
  faInstagram,
  faTimes,
  faCaretDown,
  faEllipsisH,
  faSignOut,
  faUser,
  faUserEdit,
  faFile,
  faCalendarAlt,
  faEye,
  faEyeSlash,
  faTimesCircle,
  faLineColumns,
  faLinkedinIn,
  faCheckSquare,
  faMapMarkerAlt,
  fasFaChevronRight,
  fasFaChevronLeft,
  faGripVertical,
  faEllipsisV,
  faUserTie,
  faEnvelope,
  faBars,
  faBell,
  faList,
  faBuilding,
  faRepeat,
  faSlidersV,
  faSort,
  faTasks,
  faTelegramPlane,
  faTwitter,
  faFacebookF,
  faUsers,
  faYoutube,
  faQuestionCircle,
  faExclamationTriangle,
  faCloudDownloadAlt,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
