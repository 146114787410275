export const mutations = {
  SET_IMAGE(state, payload) {
    state.user.image = payload;
  },
};

export const actions = {
  setUserImage({state, commit}, payload) {
    commit('SET_IMAGE', payload);
  },
};
