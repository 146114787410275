const middleware = {}

middleware['auth-beheerder'] = require('../middleware/auth-beheerder.js')
middleware['auth-beheerder'] = middleware['auth-beheerder'].default || middleware['auth-beheerder']

middleware['clearValidationErrors'] = require('../middleware/clearValidationErrors.js')
middleware['clearValidationErrors'] = middleware['clearValidationErrors'].default || middleware['clearValidationErrors']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['pages'] = require('../middleware/pages.js')
middleware['pages'] = middleware['pages'].default || middleware['pages']

export default middleware
