import nl from 'vuetify/es5/locale/nl';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

export default {
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        anchor: '#b9005f',
        primary: {
          base: '#b9005f',
          lighten1: '#ce095f',
          lighten2: '#ce0b7b',
          darken1: '#810642',
        },
        secondary: {
          base: '#434343',
          lighten1: '#7b7b7b',
          lighten2: '#cbcbcb',
          lighten5: '#d1d1d1',
          darken1: '#2e2e2e',
        },
        background: {
          base: '#f2f2f2',
          lighten1: '#fafafa',
          lighten5: '#ffffff',
          darken1: '#e4e4e4',
        },
        accent: '#ffffff', // pink
        accent1: '#ffd05f', // yellow
        accent2: '#46becd', // blue
        accent3: '#25ae93', // green
        success: '#25ae93',
        info: '#46becd',
        error: '#ca2d3c',
        warning: '#ffd05f',
      },
      dark: {
        anchor: '#b9005f',
        primary: {
          base: '#b9005f',
          lighten1: '#ce095f',
          lighten2: '#ce0b7b',
          darken1: '#810642',
        },
        secondary: {
          base: '#434343',
          lighten1: '#7b7b7b',
          lighten2: '#cbcbcb',
          lighten5: '#d1d1d1',
          darken1: '#2e2e2e',
        },
        background: {
          base: '#f2f2f2',
          lighten1: '#fafafa',
          lighten5: '#ffffff',
          darken1: '#e4e4e4',
        },
        accent: '#dc54a2', // pink
        accent1: '#ffd05f', // yellow
        accent2: '#46becd', // blue
        accent3: '#25ae93', // green
        accent4: '#ca2d3c', // red
        success: '#25ae93',
        info: '#46becd',
        error: '#ca2d3c',
        warning: '#ffd05f',
      },
    },
  },
  icons: {
    iconfont: 'faSvg',
    values: {
      calendar: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'calendar-alt'],
        },
      },
      checkboxIndeterminate: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fal', 'minus-square'],
        },
      },
      checkboxOff: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fal', 'square'],
        },
      },
      checkboxOn: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'check-square'],
        },
      },
      clone: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fal', 'clone'],
        },
      },
      crossCircle: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'times-circle'],
        },
      },
      download: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fal', 'download'],
        },
      },
      dropdown: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'caret-down'],
        },
      },
      down: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fal', 'chevron-down'],
        },
      },
      downreg: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'chevron-down'],
        },
      },
      edit: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fal', 'edit'],
        },
      },
      file: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fal', 'file'],
        },
      },
      expand: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'caret-down'],
        },
      },
      infocircle: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'info-circle'],
        },
      },
      warning: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'exclamation-triangle'],
        },
      },
      linecolumns: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'line-columns'],
        },
      },
      next: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'chevron-right'],
        },
      },
      options: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fa', 'ellipsis-h'],
        },
      },
      plus: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fal', 'plus-circle'],
        },
      },
      plusreg: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'plus'],
        },
      },
      prev: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'chevron-left'],
        },
      },
      search: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'search'],
        },
      },
      sort: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'sort'],
        },
      },
      cross: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'times'],
        },
      },
      signout: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fal', 'sign-out-alt'],
        },
      },
      times: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'times'],
        },
      },
      trash: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fal', 'trash-alt'],
        },
      },
      user: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fal', 'user'],
        },
      },
      visibility: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'eye-slash'],
        },
      },
      visibility_off: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'eye'],
        },
      },
      solid_left: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'chevron-left'],
        },
      },
      solid_right: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'chevron-right'],
        },
      },
      options_vert: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fal', 'ellipsis-v'],
        },
      },
    },
  },
  lang: {
    locales: {nl},
    current: 'nl',
  },
};
