export const state = () => ({
  release: process.env.RELEASE,
  drawer: null,
  settings: {},
  page: {},
  editUser: false,
  notificationsDrawer: false,
});

export const actions = {
  async nuxtServerInit({commit}) {
    // const {data} = await this.$axios.get(`/settings`);
    //
    // commit('SET_SETTINGS', data);
  },

  async getPage({commit}, slug = '') {
    let page;

    if (slug === 'login') {
      page = {
        title: 'Inloggen',
      };
    } else if (slug === '404') {
      page = {
        title: 'Pagina niet gevonden',
      };
    } else {
      const response = await this.$axios.get(`/page?segments=${slug}`);
      page = response.data.data;
    }

    commit('SET_PAGE', page);
  },
};

export const mutations = {
  SET_SETTINGS(state, payload) {
    state.settings = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_DRAWER(state, payload) {
    state.drawer = payload;
  },
  SET_EDIT_USER(state, payload) {
    state.editUser = payload;
    state.notificationsDrawer = false;
  },
  SET_NOTIFICATIONS(state, payload) {
    state.notificationsDrawer = payload;
    state.editUser = false;
  },
};
