export const state = () => ({
  notifications: [],
});

export const getters = {
  unread: (state) => {
    return state.notifications.filter(notification => !notification.read_at).length;
  },
};

export const mutations = {
  SET_ALL(state, payload) {
    state.notifications = payload;
  },
  PUSH_NOTIFICATION(state, payload) {
    state.notifications.unshift(payload);
  },
  READ_NOTIFICATION(state, payload) {
    state.notifications = state.notifications.map((item) => {
      if (item.id === payload.id) {
        item.read_at = new Date();
      }
      return item;
    });
  },

};

export const actions = {
  async getNotifications({commit}) {
    try {
      const {data} = await this.$axios.get('/me/notifications');
      commit('SET_ALL', data.data.notifications);
    } catch (e) {
      console.error(e);
    }
  },
};
