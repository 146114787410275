<template>
  <section class="menu">
    <v-list class="menu__list d-flex primary no-gutters br-0 pa-0 pl-2">
      <v-list-item
        v-for="item in itemsBasis"
        :key="item.title"
        v-bind="item.route"
        :ripple="false"
        class="menu__list-item col primary"
        active-class="primary lighten-2"
        tile
      >
        <v-list-item-title class="caption text-center">
          <font-awesome-icon :icon="item.icon" class="white--text pr-0 pr-lg-2" size="lg" fixed-width/>
        </v-list-item-title>
      </v-list-item>
      <v-dialog
        v-model="dialog"
        fullscreen
        transition="scroll-x-transition"
      >
        <v-card class="primary" dark>
          <v-card-title class="pa-0">
            <v-col class="text-right pa-0">
              <v-btn text tile dark min-width="50" height="50" class="primary darken-1 px-0" @click="dialog = false">
                <font-awesome-icon :icon="['fas', 'ellipsis-v']"/>
              </v-btn>
            </v-col>
          </v-card-title>
          <v-card-text class="nav--list mt-5">
            <v-btn
              center
              icon
              class="primary darken-1 mr-6"
              @click="toggleUserEdit"
            >
              <font-awesome-icon :icon="['fas', 'user-edit']" size="sm"/>
            </v-btn>
            <v-btn
              center
              icon
              nuxt
              class="primary darken-1"
              @click="toggleNotifications"
            >
              <v-badge right overlay color="red">
                <template v-if="unread > 0" v-slot:badge>{{ unread }}</template>
                <font-awesome-icon :icon="['fas', 'bell']" size="sm"/>
              </v-badge>
            </v-btn>
            <v-list class="transparent pt-8 pb-0">
              <v-list-item
                v-for="item in itemsGebruiker"
                :key="item.title"
                v-bind="item.route"
                class="pl-0 py-3 mt-1"
                :ripple="false"
                @click="dialog = false"
              >
                <v-list-item-title class="caption">
                  <font-awesome-icon :icon="item.icon" class="mr-3 pr-0 pr-lg-2" size="lg" fixed-width/> {{ item.title }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-card-actions class="logout--btn pa-0">
            <v-btn block depressed tile color="primary darken-1" height="62px" @click="logOut">
              <span>
                <font-awesome-icon :icon="['fas', 'sign-out']" class="subtitle-2 mr-3"/>
              </span>
              <span class="caption font-weight-medium text-capitalize">
                Uitloggen
              </span>
            </v-btn>
          </v-card-actions>
        </v-card>
        <template v-slot:activator="{ on }">
          <v-list-item :ripple="false" class="menu__list-item col primary darken-1" active-class="primary lighten-2" tile v-on="on">
            <v-list-item-title class="text-center white--text">
              <font-awesome-icon :icon="['fal', 'ellipsis-h']" size="2x"/>
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-dialog>
    </v-list>
  </section>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  data() {
    return {
      dialog: false,
      // TODO: functie om uit 1 lijst te putten (Basis en Gebruiker)?
      itemsBasis: [
        {
          title: 'Dashboard',
          route: {to: '/', nuxt: true},
          icon: ['fas', 'home'],
        },
        {
          title: 'Onderwijsinstellingen',
          route: {to: '/onderwijsinstellingen', nuxt: true},
          icon: ['fas', 'hands-helping'],
        },
        {
          title: 'Gebruikers',
          route: {to: '/gebruikers', nuxt: true},
          icon: ['fas', 'users'],
        },
        {
          title: 'Nieuws',
          route: {to: '/nieuws', nuxt: true},
          icon: ['fas', 'newspaper'],
        },
        {
          title: 'FAQ',
          route: {to: '/faq', nuxt: true},
          icon: ['fas', 'question-circle'],
        },
      ],
      itemsGebruiker: [
        {
          title: 'Dashboard',
          route: {to: '/', nuxt: true},
          icon: ['fas', 'home'],
        },
        {
          title: 'Onderwijsinstellingen',
          route: {to: '/onderwijsinstellingen', nuxt: true},
          icon: ['fas', 'hands-helping'],
        },
        {
          title: 'Organisaties',
          route: {to: '/organisaties', nuxt: true},
          icon: ['fas', 'building'],
        },
        {
          title: 'Gebruikers',
          route: {to: '/gebruikers', nuxt: true},
          icon: ['fas', 'users'],
        },
        {
          title: 'Nieuws',
          route: {to: '/nieuws', nuxt: true},
          icon: ['fas', 'newspaper'],
        },
        {
          title: 'FAQ',
          route: {to: '/faq', nuxt: true},
          icon: ['fas', 'question-circle'],
        },
        {
          title: 'Contact',
          route: {to: '/contact', nuxt: true},
          icon: ['fas', 'phone-volume'],
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      unread: 'notifications/unread',
    }),
  },
  methods: {
    async logOut() {
      try {
        await this.$auth.logout();
        this.$router.push('/inloggen');
      } catch (e) {
        console.log(e);
      }
    },
    toggleUserEdit() {
      this.$store.commit('SET_EDIT_USER', !this.editUserState);
      this.dialog = false;
    },
    toggleNotifications() {
      this.$store.commit('SET_NOTIFICATIONS', !this.notificationsState);
      this.dialog = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.menu
  width: 100%

  &__list
    height: 50px

.nav--list
  padding-left: 25% !important

.logout--btn
  position: fixed
  bottom: 0
  width: 100%

.v-list--nav .v-list-item:not(:last-child):not(:only-child)
  margin-bottom: 0

//::v-deep .v-btn:not(.v-btn--round).v-size--default
  min-width: 50px
</style>
