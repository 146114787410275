<template>
  <v-app>
    <alert/>
    <snackbar/>
    <v-content>
      <nuxt/>
    </v-content>
  </v-app>
</template>

<script>
import Alert from '~/layouts/partials/Alert';
import Snackbar from '~/layouts/partials/Snackbar';

export default {
  components: {
    Snackbar,
    Alert,
  },
};
</script>
