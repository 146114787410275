<template>
  <v-app>
    <alert/>
    <snackbar/>
    <client-only>
      <drawer/>
    </client-only>
    <v-content>
      <edit-user/>
      <notifications/>
      <nuxt/>
    </v-content>
    <app-footer/>
  </v-app>
</template>

<script>
import Alert from '~/layouts/partials/Alert';
import Drawer from './partials/Drawer';
import Snackbar from '~/layouts/partials/Snackbar';
import Notifications from '@/layouts/partials/Notifications';
import AppFooter from '~/layouts/partials/Footer';

const EditUser = () => ({
  component: import('@/layouts/partials/EditUser'),
});

export default {
  components: {
    Alert,
    Snackbar,
    Drawer,
    EditUser,
    Notifications,
    AppFooter,
  },
  mounted() {
    this.lockScreenOrientation();
    this.$echo.private(`App.Models.User.${this.user.id}`)
      .notification((notification) => {
        const message = `${notification.user} ${notification.message} ${notification.new_state || ''} ${notification.comment || ''}`;
        this.$store.commit('notify/success', message);
        const newNotification = {
          created_at: new Date(),
          data: {
            comment: notification.comment || '',
            activity_id: notification.activity_id,
            message: notification.message,
            user: notification.user,
          },
          id: notification.id,
          read_at: null,
        };
        this.$store.commit('notifications/PUSH_NOTIFICATION', newNotification);
      });
  },
  methods: {
    lockScreenOrientation() {
      if (this.$device.isMobile) {
        screen.orientation.lock('portrait');
      }
    },
  },
};

</script>

<style lang="sass">
.nuxtchild-enter-active, .nuxtchild-leave-active
  transition: opacity .2s

.nuxtchild-enter, .nuxtchild-leave-active
  opacity: 0

</style>
