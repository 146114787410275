<template>
  <v-slide-x-transition>
    <v-row v-if="editUser" class="edit-user background lighten-5 shadow-overlay flex-column fill-height" no-gutters>
      <v-col class="px-8 pb-8">
        <v-row align="center">
          <v-col tag="h1" cols="10" class="subtitle-1 text-uppercase primary--text">
            Profiel
          </v-col>
          <v-col cols="2" class="text-right pr-0">
            <v-btn icon color="primary darken-1" @click="toggleUser">
              <font-awesome-icon :icon="['far', 'times']" size="lg"/>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="profile--box">
          <v-col cols="12" sm="6" class="pr-4 pb-0 pb-sm-3">
            <div class="image-container">
              <img
                class="border-radius-15 shadow-profile user--avatar"
                :src="user.image || ''"
              >
              <v-file-input
                id="inputField"
                v-model="newImage"
                accept="image/*"
                style="display: none"
              />
              <v-btn
                icon
                small
                dark
                class="primary darken-1 image-edit"
                @click="toggleFileInput()"
              >
                <font-awesome-icon :icon="['fas', 'pen']" size="2x"/>
              </v-btn>
            </div>
            <v-row>
              <v-col class="py-5">
                <div class="subtitle-1 primary--text">{{ form.name }}</div>
                <div class="caption secondary--text">{{ form.role.label }}</div>
              </v-col>
            </v-row>
            <form method="post" @submit.prevent="submit">
              <v-text-field autocomplete="street-adress" v-model="form.location.adres" outlined
                            label="Straat + Huisnr"/>
              <v-text-field autocomplete="postal-code" v-model="form.location.postalcode" outlined label="Postcode"/>
              <v-text-field autocomplete="address-level2" v-model="form.location.city" outlined label="Plaats"/>
              <v-text-field autocomplete="email" v-model="form.email" type="email" outlined label="Email" disabled/>
              <v-text-field autocomplete="tel" v-model="form.phone" outlined label="Telefoonnummer"/>
            </form>
            <v-divider/>
            <v-row>
              <v-col>
                <div class="caption secondary--text py-4">
                  <label>Notificaties via email ontvangen: {{wantsEmail ? 'Aan' : 'Uit'}}</label>
                  <v-switch
                    v-model="wantsEmail"
                    dark
                    hide-details
                  />
                </div>
              </v-col>
            </v-row>
            <v-divider/>
            <div class="caption secondary--text py-4">Nieuw wachtwoord instellen</div>
            <form method="post" @submit.prevent="submitNewPassword">
              <v-text-field autocomplete="current-password" v-model="newPassword.password" type="password" outlined
                            label="Uw huidige wachtwoord" :error-messages="errors.password"/>
              <v-text-field autocomplete="new-password" v-model="newPassword.new_password" type="password" outlined
                            label="Uw nieuwe wachtwoord" :error-messages="errors.new_password"/>
              <v-text-field v-model="newPassword.new_password_confirmation" type="password" outlined
                            label="Herhaal uw nieuwe wachtwoord"
                            :error-messages="errors.new_password"/>
              <v-btn depressed block color="primary darken-1" @click="submitNewPassword">
                <div v-if="!loadingPassword">Wachtwoord opslaan</div>
                <v-progress-circular v-else color="primary" indeterminate/>
              </v-btn>
            </form>
          </v-col>
          <v-col cols="12" sm="6" class="pl-6 pb-0 pb-sm-3">
            <v-row>
              <v-col>
                <div class="subtitle-1 primary--text">{{ form.organisation.name }}</div>
                <v-row>
                  <v-col v-show="$breakpoint.is.mdAndUp" class="pr-2 pt-4 shrink secondary--text">
                    <font-awesome-icon class="caption" :icon="['fas', 'map-marker-alt']"/>
                  </v-col>
                  <v-col v-show="$breakpoint.is.mdAndUp" cols="10" class="pt-4 pb-2 px-0">
                    <div class="caption secondary--text">{{ form.organisation.location.adress }}</div>
                    <div class="caption secondary--text">{{ form.organisation.location.postalcode }}</div>
                    <div class="caption secondary--text">{{ form.organisation.location.city }}</div>
                  </v-col>
                  <v-col cols="11" class="py-2">
                    <font-awesome-icon class="caption" :icon="['fas', 'envelope']"/>
                    <span class="pl-1 caption secondary--text">{{ form.organisation.email }}</span>
                  </v-col>
                  <v-col cols="11" class="py-2">
                    <font-awesome-icon class="caption" :icon="['fas', 'phone']"/>
                    <span class="pl-1 caption secondary--text">{{ form.organisation.phone }}</span>
                  </v-col>
                  <v-col cols="12">
                    <div class="caption secondary--text pt-2 pb-2">Collega’s:</div>
                    <div v-for="colleague in form.organisation.users" :key="colleague.id">
                      <div v-if="colleague.id !== user.id" class="pb-3">
                        <nuxt-link class="no-underline" :to="`/gebruikers/${colleague.id}`">
                          <div class="d-flex align-center">
                            <v-avatar tile size="20px" class="border-radius-4"><img :src="colleague.image || ''"
                                                                                    :alt="colleague.name"></v-avatar>
                            <span
                              class="caption text-capitalize text-truncate primary--text pl-2">{{ colleague.name }}</span>
                          </div>
                        </nuxt-link>
                      </div>

                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="d-flex align-end justify-end mb-4">
            <v-btn depressed color="primary darken-1" @click="submit">
              <div style="width : 100px;">
                <div v-if="!loading">Opslaan</div>
                <v-progress-circular v-else color="primary" indeterminate/>
              </div>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-slide-x-transition>
</template>

<script>
import {mapState} from 'vuex';

export default {
  data() {
    return {
      form: {},
      newPassword: {},
      newImage: null,
      loading: false,
      loadingPassword: false,
      wantsEmail: this.$auth.user.wants_email,
    };
  },
  computed: {
    ...mapState({
      editUserState: state => state.editUser,
    }),
    editUser: ({
      set(state) {
        this.$store.commit('SET_EDIT_USER', state);
      },
      get() {
        return this.editUserState;
      },
    }),
  },
  watch: {
    $route() {
      this.closeUserEdit();
    },
    newImage(val) {
      this.editImage(val);
    },
    wantsEmail(val) {
      this.toggleWantsEmail(val);
    }
  },
  created() {
    this.form = JSON.parse(JSON.stringify(this.user));
  },
  methods: {
    async submit() {
      this.loading = true;

      try {
        await this.$axios.post(`/me`, this.form);
        this.$store.commit('notify/success', 'Succesvol opgeslagen');
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },
    async submitNewPassword() {
      this.loadingPassword = true;

      try {
        await this.$axios.post(`/password/change`, this.newPassword);
        this.$store.commit('notify/success', 'Wachtwoord succesvol opgeslagen');
      } catch (e) {
        console.log(e);
      }

      this.loadingPassword = false;
    },
    toggleUser() {
      this.$store.commit('SET_EDIT_USER', !this.editUserState);
    },
    closeUserEdit() {
      this.$store.commit('SET_EDIT_USER', false);
    },
    toggleFileInput() {
      document.getElementById('inputField').click();
    },
    async toggleWantsEmail(bool) {
      try {
        await this.$axios.post('/me/preference', {wants_email: bool});
      } catch (e) {
        console.log(e);
      }
    },
    async editImage(file) {
      try {
        const formData = new FormData();
        formData.append('file', file);
        await this.$axios.post(`/me/upload`, formData);
        const {data} = await this.$axios.get('/me');
        this.$store.dispatch('auth/setUserImage', data.data.image);
      } catch (e) {
        console.error(e);
      }
    },
  },

};
</script>

<style scoped lang="sass">
.image-container
  position: relative
  width: 100%
  padding-top: 100%

  .image-edit
    position: absolute
    top: 5%
    left: 5%

  .user--avatar
    position: absolute
    top: 0
    left: 0
    height: 100%
    object-fit: cover

.edit-user
  position: fixed
  width: 100vw
  z-index: 8
  top: 0
  height: 100vh

  .profile--box
    max-height: 82vh
    overflow: scroll

// input styling

::v-deep .v-input

  &:not(.v-input--is-disabled) input, textarea
    color: var(--v-secondary-lighten1) !important
    font-size: 16px
    font-weight: 300

@media screen and (min-width: map-get($grid-breakpoints, 'sm'))
  .edit-user
    width: 55vw

    .profile--box
      max-height: 85vh
      overflow: scroll

@media screen and (min-width: map-get($grid-breakpoints, 'lg'))
  .edit-user
    width: 40vw

</style>
